import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/porazki-wdrozen-crm.jpg";
import Lightbox from "../components/lightBox";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "crm-proces-sprzedazy-elastyczny-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(
      relativePath: { eq: "co-to-jest-crm-baza-kontrahentow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Porazki = ({ data }) => {
  return (
    <Artykul
      title="Porażki wdrożeń CRM - dlaczego się nie udaje?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Porażki wdrożeń CRM - dlaczego się nie udaje?"
      metaTitle="Porażki wdrożeń CRM - dlaczego się nie udaje?"
      metaDescription="Wdrożenie CRM to zmiana w organizacji • Kiedy skończy się sukcesem a kiedy niepowodzeniem? • Jak ustrzec się przed błędami? • Video "
    >
      
      <p style={{
          paddingTop: 30,
        }}>W naszej nowej serii, tym razem wywiadów, rozmawiamy z praktykami biznesu o sprzedaży, obsłudze klienta i&nbsp;szeroko o technologii. Dużo mówi się o sukcesach wdrożeń oprogramowania, dlatego postanowiliśmy przełamać schemat i porozmawiać o temacie tabu - czyli porażkach w obszarze wdrożeń CRM.
</p>
<p>
W tym odcinku, Joanna Cieluch rozmawia z Łukaszem Tadyszakiem - założycielem i CEO Questy. <strong>Jeśli chcesz poznać najczęstsze powody nieudanych wdrożeń i ustrzec się przed nimi - zachęcamy do zapoznania się z materiałem video lub transkrypcją rozmowy. </strong>
</p>
     <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="IFcregJWj8c"
          title="Porażki wdrożeń CRM"
        />
      </div>

      <p style={{
          paddingTop: 20,
        }}><strong> 
        Zacznę kontrowersyjnie. Czy nieudane wdrożenie CRM może być winą dostawcy oprogramowania?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Oczywiście. Winny może być dostawca. Dostawcy mają różne doświadczenia. Dla przykładu: nasza firma - 18 lat temu miała doświadczenie “zero lat”. Siłą rzeczy, nasze doświadczenie jako doradcy, który może ustrzec klienta przed ewentualnymi ślepymi uliczkami było po prostu dużo mniejsze.
</p>
<p>Także, w mojej ocenie, jak najbardziej dostawca ma duży wpływ na sukces lub porażkę danego projektu.

        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
       Czyli stawiamy na dostawcę doświadczonego, który ma za sobą realizację wielu projektów?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Myślę, że doświadczenie to jedno. Po pierwsze, dostawcy mają doświadczenie z różnych typów projektów. Czym innym jest wdrażanie systemu, który będzie wspierał duże call center. A czym innym będzie wdrażanie projektów w mikrofirmach, które dopiero zaczynają albo w specyficznych branżach o określonych procesach, które nie występują gdzie indziej. To doświadczenie należy więc rozpatrywać w kontekście naszej firmy. 
 </p>
 <p>Druga kwestia. Dostawcy zatrudniają też ludzi o różnym doświadczeniu. Czasami warto zapytać o doświadczenie konkretnego Project Managera. Dopiero zaczyna pracę czy pracuje w tej branży np. 10 lat?
</p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Jak już jesteśmy przy ludziach. W firmie są różne role. Czy któraś z nich może mieć szczególne znaczenie w przypadku nieudanych wdrożeń - np. zarząd, kierownik projektu? 

        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
    Tak, to jest temat trochę tabu. Czasami zarząd kupuje rozwiązanie i próbuje, aby to pracownicy poprowadzili zarządzanie zmianą w organizacji. Tego typu podejście raczej zakończy się porażką. 
        </p>
        <p>Myślę, że zarówno trzeba włączyć interesariuszy w proces wyboru dostawcy, jak i później nie można pracowników szeregowych zostawiać ze wszystkimi problemami, które w trakcie zarządzania zmianą wypłyną. Ludzie będą mieli musieli podejmować decyzje na gorąco, nie będą mieli też siły sprawczej więc mogą być sfrustrowani i ten projekt może się po prostu nie udać. </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        A czy jedną z przyczyn nieudanego wdrożenia jest to że firma wybiera rozwiązanie niedopasowane do swoich potrzeb?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
        Oczywiście. Pod hasłem “rozwiązania CRM” kryje się bardzo wiele. Można powiedzieć, że niektóre firmy tworzą cokolwiek, jakaś bazę danych, przyklejają etykietę CRM i mówią ”proszę zobaczcie, my również coś tam mamy”. Z grubsza można powiedzieć, że jest to “CRM”, ale pojawia się pytanie - czy rozwiąże problemy naszej organizacji? 
</p>
<p>Przyjąłbym, po pierwsze, że wybierając rozwiązanie musimy dokładnie ocenić co chcemy zrobić, co nam ten system ma dać.


       </p>
                <p style={{
          paddingTop: 15,
        }}><strong> 
     Czyli analizujemy problemy i potrzeby?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
        Tak jest, co więcej powinniśmy problemy i potrzeby poukładać w formie hierarchii. To nam pomoże wybrać odpowiednie rozwiązanie, które na pewno nam pomoże.
</p>
<p>Mogę dołożyć jedną rzecz, żeby utrudnić spojrzenie na ten problem :) </p>

<p>Nasza firma będzie się zmieniała. Jeśli projekt odniesie lub nie odniesie sukcesu to możemy za pół roku być w trochę innej rzeczywistości. Jednym z elementów, które mogą być decydujące jest to czy dane rozwiązanie jest na tyle elastyczne, że pomoże w tych nowych realiach, które dopiero nadejdą.
</p>
<p>Chociażby dzisiaj w związku z epidemią, nagle większość firm musiała przejść na pracę zdalną. Można powiedzieć, że to było takie “sprawdzam” dla wielu projektów CRM, czy faktycznie narzędzia zapewniały sprawną komunikację między pracownikami rozproszonymi.

   </p>
                        <p style={{
          paddingTop: 15,
        }}><strong> 
        Czy coś jeszcze z porażek? 
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
     Myślę, że porażką będzie jeśli wdrożenie procesu, który sobie wybierzemy po prostu nic nie przyniesie. Wróciłbym do swojej poprzedniej wypowiedzi. Jeśli wybierzemy problemy w naszej firmie - na przykład problemem może być to, że tracimy wiedzę o kliencie wraz z pracownikiem - to powinniśmy starać się wdrażać tak rozwiązanie, aby te problemy faktycznie rozwiązać. Niekoniecznie wdrażać, nazwijmy to excitement features, które będą owszem ekscytujące, ale nic nam nie dadzą. Tylko właśnie wdrażać te elementy, które zmienią naszą sytuację.
</p>
<p>CRM może na przykład rozwiązać problem z szybkością obsługi szans sprzedaży (część obsługiwana jest szybko, a część po znacznym czasie). Przyjmijmy, że marketing wydał milion złotych na reklamę i tylko część z tej kampanii została skonsumowana w prawidłowy sposób. To CRM powinien nam pomóc zdiagnozować sytuację i pokazać czy to jest problem jednego człowieka, zespołu, a może roli, która jest wąskim gardłem? 
 </p>
                                <p style={{
          paddingTop: 15,
        }}><strong> 
        W zasadzie chodzi o to, żebyśmy zaczynali wdrożenie od kluczowych procesów. Czyli nie zajmowali się czymś mało istotnym ani procesem, który obecnie świetnie sobie radzi. Tylko zaczynamy od procesu, który najbardziej potrzebuje wsparcia?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
     Tak, nawet powiem więcej. Część osób myśli w ten sposób, że samo wdrożenie CRM-a jest trudne. Ludzie boją się dzielić wiedzą. Obawiają się dodatkowej pracy administracyjnej. I to jest prawda, tak może być. Zwłaszcza wtedy, gdy będziemy wdrażać rzeczy, które działają w naszej firmie. Jeżeli pracownikom dołożymy dodatkową dodatkową biurokrację to nie oczekiwałbym, że będą zadowoleni z takiego obrotu sprawy.
 
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Ok, a czy może być po prostu tak, że źle wdrażamy? Nie ważne czy dostawca czy klient, czy razem. Po prostu źle podeszliśmy do tematu wdrożenia.
         </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Oczywiście, że tak. Użyjmy przykładu. Czasami jak rozmawiamy o kryteriach segmentacji klienta, czy wiedzy o kliencie. To część osób myśli sobie, że chciałaby wiedzieć wszystko. Tworzy na przykład kartotekę, która ma pięćdziesiąt pól. Efekt jest taki, że pracownikom uzupełnienie tych informacji zabiera mnóstwo czasu. Dlatego część z nich wykona tą pracę i można powiedzieć, wpłynie to negatywnie na ich produktywność. Część nie wykona wcale, a reszta tylko częściowo. A po pół roku będziemy mieć taki miszmasz. Nie będziemy wiedzieli czy to na co patrzymy to jest prawda i czy jest to aktualne czy nie? Może ktoś to uzupełnił byle jak, aby nie zostawiać pustego pola? 
</p>
<p>To jest przykład złego podejścia do wdrażania systemów CRM. Przed takimi rzeczami powinien nas ostrzec dostawca.

        </p>
    
         <p style={{
          paddingTop: 15,
        }}><strong> 
       Czyli o CRM-ie nie myślimy jak o bazie informacji o wszystkim. Nie zbieramy tam rzeczy, które są niepotrzebne. Czy dobrze zrozumiałam?

        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
    Tak, każda informacja, której koszt zbierania chcemy ponieść (bo tak powinniśmy na to patrzeć) powinna być użyteczna. Jeżeli nie mamy konkretnego pomysłu co z nią zrobimy (tak konkretnie) to lepiej, żebyśmy tego nie robili. To będzie zmarnowany pieniądz, zmarnowany czas pracowników i mniejsza szansa na sukces.

        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
       Żebyśmy to spotkanie zakończyli sukcesem to może zbierzmy w klamrę dobre rady i informacje jak się ustrzec przed złym wdrożeniem?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
     Myślę, że jakbyśmy odwrócili rzeczy o których mówiliśmy to już mamy duże szanse na sukces. Czyli po pierwsze, odpowiedni i doświadczony dostawca oraz odpowiedni system CRM, który ma odpowiadać naszym potrzebom i dzisiaj i w przyszłości. Poza tym odpowiedni poziom zaangażowania zarówno zarządu jak i pracowników. Nastawienie, że jest to zmiana, którą trzeba przeprowadzić od A do Z. Myślę, że bardzo ważny jest wybór właściwych priorytetów. I ostatnia kwestia - zdroworozsądkowe podejście do każdego elementu, który wdrażamy tak żeby on faktycznie rozwiązywał nasze problemy, a nie przysparzał nowych.
</p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>

      <p>
        <strong>Zachęcamy do obejrzenia również:</strong>
        <ul>
          <li>
            <Link to="/co-to-jest-crm/">Co to jest CRM? Wywiad z ekspertem</Link>
          </li>
          <li>
            <Link to="/proces-sprzedazy/">
              {" "}
              Proces sprzedaży - zaprojektuj go mądrze
            </Link>
          </li>
         
        </ul>
      </p>
    </Artykul>
  );
};

export default Porazki;
